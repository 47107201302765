import React from "react";
import { Carousel } from "rsuite";
import "@splidejs/splide/dist/css/splide.min.css";
import useFetchData from "../../services/useFetchData";
import BannerSkeleton from "../../AppPages/Skeleton/BannerSkeleton";
import useDateManager from "../../services/DateManager"; // Import du hook

const SliderComponent = ({ numberSlidesToShow = 1, ImagelBaseUrl }) => {
  const dates = useDateManager(); // Appel correct du hook

  const { data: sliderData, loading, error } = useFetchData(
    process.env.REACT_APP_CONFIGURATION_MANAGER_API_URL,
    {
      mode: process.env.REACT_APP_LIST_BANNIERE_MODE,
      DT_BEGIN: "2025-01-01",
      DT_END: "2025-02-28"
    }
  );

  return (
    <section className="home home--hero">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {loading || !sliderData || sliderData.length === 0 ? (
              <BannerSkeleton />
            ) : (
              <Carousel autoplay className="custom-slider" style={{ height: 550 }}>
                {sliderData.map((item, index) => (
                  <img
                    key={index}
                    src={`${ImagelBaseUrl}${item.STR_BANPATH}`}
                    alt={item.title || `Slide ${index + 1}`}
                  />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderComponent;
