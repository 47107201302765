import React, { useState, useEffect } from "react";
import useFetchData from "../../services/useFetchData";
import AppHeader from "../../AppComponents/Header/AppHeader";
import SliderComponent from "../../AppComponents/Slider/SliderComponent";
import { urlBaseImage, rootUrl } from '../../services/urlUtils';
import ExpectedPremiere from "./ExpectedPremiere";
import BannerSkeleton from "../../AppPages/Skeleton/BannerSkeleton";



const EventList = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (searchTerm) => { setSearchTerm(searchTerm); };
  const { data: activitesData, loading, error } = useFetchData( process.env.REACT_APP_TICKET_MANAGER_API_URL,
    { mode: process.env.REACT_APP_LIST_EVENEMENT_FRONT_MODE, DT_BEGIN: "2025-01-01", DT_END: "2026-02-28" }
  );

  if (loading)
    return <section className="home home--hero">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BannerSkeleton />
          </div>
        </div>
      </div>
    </section>;
  if (error) return <p>{error}</p>;

  // Afficher les événements si disponibles
  return (
    <div>
      <AppHeader onSearch={handleSearch} />
      <SliderComponent ImagelBaseUrl={urlBaseImage} />
      {activitesData && activitesData.length > 0 ? (
        <ExpectedPremiere
          ImagelBaseUrl={urlBaseImage}
          fullUrl={rootUrl}
          data={activitesData}
          searchTerm={searchTerm}
        />
      ) : (
        <p>Aucun événement à afficher.</p>
      )}
    </div>
  );
};

export default EventList;
