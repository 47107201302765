import { useEffect, useState } from "react";

const useDateManager = () => {
  const [dates, setDates] = useState({
    DT_BEGIN: "",
    DT_END: "",
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    const formatDate = (date) => date.toISOString().split("T")[0];

    setDates({
      DT_BEGIN: formatDate(firstDayOfYear),
      DT_END: formatDate(nextMonth),
    });
  }, []);

  return dates;
};

export default useDateManager;
